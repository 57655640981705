<template>

    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="3" class="mb-md-0 mb-2">

                        <b-form-select v-model="siteData" @change="filterTable">

                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                          
                          <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                          
                          
                        </b-form-select>
                    </b-col>

                    <b-col cols="12" md="3">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="months"
                          
                          class="w-100"
                          placeholder="Month"
                          @input="filterTable"
                          v-model="month"
                          :clearable="false"
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="yearData"
                          class="w-100"
                          placeholder="Year"
                          @input="filterTable"
                          v-model="year"
                          :clearable="false"
                        />
                    </b-col>

                </b-row>
            </b-card-body>
        </b-card>
        <!-- Table Container Card -->
        <b-card no-body class="mb-0">

            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            @input="filterTable"
                        />
                        <label>entries</label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                @input="filterTable"
                            />
                            <!-- <b-button v-if="checkPermission($route.name, 'Add Monthly Inspection Checklist')" variant="warning" :to="{name:'add-monthly-inspection-checklist'}">
                                <span class="text-nowrap">Add Monthly Inspection Checklist</span>
                            </b-button> -->
                        </div>
                    </b-col>

                </b-row>
            </div>

            <b-table sticky-header
                responsive
                show-empty
                ref = "refUserListTable"
                class = "position-relative"
                primary-key = "id"
                empty-text = "No matching records found"
                :fields = "tableColumns"
                :sort-by.sync = "sortBy"
                :items = "dataList"
                :sort-desc.sync = "isSortDirDesc"
            >
                <template #cell(unique_id)="items">
                    <p class="wordBreak">{{ items.item.unique_id | capitalize}}</p>
                </template>

                <template #cell(site_name)="items">
                    <p class="wordBreak">{{ items.item.site.site_name | capitalize}}</p>
                </template>

                <template #cell(building)="items">
                    <p class="wordBreak">{{ items.item.building.name | capitalize}}</p>
                </template>

                <template #cell(month)="items">
                    <p class="wordBreak">{{ items.item.month | capitalize}}</p>
                </template>

                <template #cell(year)="items">
                    <span>{{items.item.year }}</span>
                </template>

                <template #cell(updated_at)="items">
                    <span>{{items.item.updated_at | dateTime }}</span>
                </template>

                <template #cell(actions)="items">

                    <b-link
                          v-if="checkPermission('custom-forms-reports', 'Delete')"
                          v-b-tooltip.hover.v-warning
                          title="Delete"
                          variant="outline-warning"
                          @click="deleteItem(items.item._id)"
                        >
                          <feather-icon icon="Trash2Icon" class="mr-1 mediumSize"/>
                            
                      </b-link>

                    <b-link
                        v-if="checkPermission('custom-forms-reports', 'Download')"
                        v-b-tooltip.hover.v-warning
                        title="Download"
                        variant="outline-warning"
                        @click="download(items.item.pdf)"
                    >
                        <feather-icon icon="DownloadIcon" class="mr-1 mediumSize"/>
                    </b-link>
                </template>

            </b-table>

            <div class="mx-2 mb-2">
                <b-row>

                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecords"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                        @input="filterTable"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>

                </b-row>
            </div>

        </b-card>
    </div>
</template>

<script>
import {
    BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BFormDatepicker,BFormSelect,BFormSelectOption, BBreadcrumb
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment";

export default {
    components: {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormDatepicker,BFormSelect,BFormSelectOption, BBreadcrumb
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            webUrl: process.env.VUE_APP_SERVER_URL,
            error_message: null, 

            tableColumns: [
                { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '25%'}},
                { key: 'site_name', label: 'Project Site Name', sortable: false , thStyle:  {width: '15%'}},
                { key: 'building', label: 'Building Name', sortable: false , thStyle:  {width: '15%'}},
                { key: 'month', label: 'Month', sortable: true , thStyle:  {width: '10%'}},
                { key: 'year', label: 'Year', sortable: true , thStyle:  {width: '10%'}},
                { key: 'updated_at', label: 'Last Updated', sortable: true , thStyle:  {width: '15%'}},
                { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '10%'}},
            ],

            sites:[],
            siteData:''/*this.$route.params.site_id*/,      
            
            // b-table and pagination options
            items           : [],
            isSortDirDesc   : true,
            perPageOptions  : [10, 20, 50, 100],
            perPage         : 10,
            sortBy          : 'id',
            totalRecords    : 0,
            currentPage     : 1,
            searchQuery     : '',
            from            : null,
            to              : null, 
            // calendar vars 
            months:[
                 'January', 
                 'February', 
                 'March', 
                 'April', 
                 'May', 
                 'June', 
                 'July ', 
                 'August', 
                 'September', 
                 'October', 
                 'November', 
                 'December'
            ],
            month:moment().format('MMMM'),
            year:parseInt(moment().format('YYYY')),
            yearData:[],
        }
    },

    methods : {
        dataList(){
            return this.$store.dispatch(POST_API, {
                data:{
                    page          : this.currentPage,
                    keyword       : this.searchQuery,
                    rowsPerPage   : this.perPage,
                    sortBy        : this.sortBy,
                    sortDirection : this.isSortDirDesc,
                    role          : this.$store.getters.currentUser.role,
                    om_sites      : this.$store.getters.currentUser.om_sites,
                    site          : this.siteData != '' ? this.siteData : null,
                    month:this.month ? this.month.toLowerCase() : null,
                    end:this.year,
                },
                api: '/api/lactation-reports'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.items          = this.$store.getters.getResults.data.docs;
                    this.totalRecords   = this.$store.getters.getResults.data.total;
                    this.perPage        = this.$store.getters.getResults.data.limit;
                    this.from           = this.$store.getters.getResults.data.from
                    this.to             = this.$store.getters.getResults.data.to

                    return this.items;
                }
            });
        },

        filterTable(){
            this.$refs.refUserListTable.refresh();
        },

        variantColor(status){
            if (status == 'active') {
                return 'success';
            }else{
                return 'primary';
            }
        },
        
        download(pdf){
            window.open(pdf, '_blank');
        },

        allSites(){
            return this.$store.dispatch(POST_API, {
                data:{
                role:this.$store.getters.currentUser.role,
                om_sites:this.$store.getters.currentUser.om_sites,
                },
                api: '/api/all-sites'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.sites = this.$store.getters.getResults.data;
                    if (this.sites.length == 1 ) {
                        this.siteData = this.sites[0]._id;
                        this.filterTable();
                    }
                    var obj = {
                        _id:'all-site',
                        site_name:'All Project Sites'
                    }
                    this.sites.unshift(obj);
                    
                    return this.sites;
                }
            });
        },

        // calendar functions
        updateStart(){
            this.end = moment(this.start).tz('Asia/Singapore').toDate();
            this.filterTable();
        },
        disabledDates(){
            if (this.start) {
                return moment(this.start).format('YYYY-MM-DD');
            }else{
                return moment().format('YYYY-MM-DD');
            }
        },
        deleteItem(id){
          var msg = 'Are you sure want to delete this record?';
          var message='Lactation Report Deleted Successfully.';

          Swal.fire({
                    title: 'Please Confirm',
                    text: msg,
                    icon: 'warning',
                    position: 'top-center',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                  })
                .then(result => {
                  if (result.value) {
                      return this.$store.dispatch(POST_API, {
                        data:{
                              id: id,
                            },
                        api:"/api/delete-lactation-report",
                          })
                          .then(() => {
                              if (this.$store.getters.containsErrors) {
                                  this.error_message = this.$store.getters.getErrors;
                                  this.showAlert();
                              } else {
                                
                                Swal.fire({
                                  position: 'center',
                                  icon: 'success',
                                  title: message,
                                  showConfirmButton: false,
                                  timer: 1500
                                })
                                
                                this.$refs.refUserListTable.refresh();
                              }
                          });
                  }
              })
              .catch(err => {
                  
              })
        },
        calYear(){
          var year = parseInt(moment().format('YYYY'));
          var yearData = [];
          var start = parseInt(year) - 2;
          var end =  parseInt(year) + 5;

          for (var i = start ; i <= end; i++) {
            yearData.push(i);
          }

          this.yearData = yearData;
        },
        breadCrumb(){
          var item = [{
            to:{name:'client-dashboard'},
            text: 'Dashboard',
          },{
            to:null,
            text: 'Reports',
          },{
            to:{name:'custom-forms-reports'},
            text:'Custom Forms'
          },{
            to:null,
            text:'Lactation Checklist',
            active:true
          }];
          return item;
        }
    },
    mounted(){
        this.allSites();
        this.calYear();
    }
}
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
